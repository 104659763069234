import $ from "jquery";

$(document).on("turbolinks:load", () => {
  $("#main-nav-toggle").click((e) => {
    e.preventDefault();
    $("#main-nav").slideToggle(150).toggleClass("nav-main--show");
  });
  $(".nav-main__link--has-dropdown").on("click keydown", function(e) {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      e.preventDefault();
      let isExpanded = $(this).attr("aria-expanded") === "true"
      $(this).attr("aria-expanded", !isExpanded);
      $(this).toggleClass("nav-main__link--dropdown-active");
      $(this).next(".nav-submenu").slideToggle(120).toggleClass("nav-submenu--show");
    }
  });

  $("#sign-in__modal-close").click((e) => {
    $("#sign-in__modal-overlay").css("display", "none");
    $("#bank-sign-in__modal").css("display", "block");
    $("#alternate-sign-in__modal").css("display", "none");
  });

  $("#sign-in-button").click((e) => {
    $("#sign-in__modal-overlay").css("display", "flex");
  });
  $("#sign-in-nav-item").click((e) => {
    $("#sign-in__modal-overlay").css("display", "flex");
  });
  $("#alternate-sign-in-button").click((e) => {
    $("#bank-sign-in__modal").css("display", "none");
    $("#alternate-sign-in__modal").css("display", "block");
  });
  $("#sign-in__back-button").click((e) => {
    $("#bank-sign-in__modal").css("display", "block");
    $("#alternate-sign-in__modal").css("display", "none");
  });
});

// Makes sure the navigation doesn't disappear when closed in mobile and screen
// is resized to desktop width.
const handleNavMediaQuery = (mql) => {
  if (mql.matches) {
    $(".nav-main").show();
  }
};

let mql = window.matchMedia("(min-width: 980px)");
mql.addListener(handleNavMediaQuery);
handleNavMediaQuery(mql);

